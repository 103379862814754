<template>
  <MatchRoundSelectorItem
    v-bind="$attrs"
    :number="round.round_number + 1"
    :winner="winner"
    :loser="loser"
    :highlighted="selected"
    :selected="active"
    :class="{ 'first-half': round.first_half }"
    :outcome="winner.won_by"
    :tag="tag"
  />
</template>

<script>
import px from 'vue-types'

import MatchRoundSelectorItem from '@/components/match/roundSelector/MatchRoundSelectorItem.vue'

import { pxId, pxNullable, pxRole, pxRoleLowercase } from '../Map/types.js'

export default {
  components: {
    MatchRoundSelectorItem,
  },

  props: {
    active: px.bool.def(false),
    round: px.shape({
      first_half: px.bool,
      round_number: px.integer.isRequired,
      teams: px.arrayOf(
        px.shape({
          id: pxId().isRequired,
          role: px.oneOfType([pxRole(), pxRoleLowercase()]).isRequired,
          won_by: pxNullable(px.oneOf(['Elimination', 'Detonate', 'Defuse', 'Time'])).isRequired,
        }).loose
      ).isRequired,
    }).loose.isRequired,
    selected: px.bool.def(false),
    tag: px.oneOfType([px.string, px.object]).def('div'),
    teams: px.arrayOf(
      px.shape({
        id: pxId().isRequired,
      }).loose
    ),
  },

  computed: {
    orderedTeams() {
      if (!this.teams) return this.round.teams
      return Object.freeze(
        this.round.teams
          .slice(0)
          .sort((a, b) => this.teams.findIndex(t => t.id === a.id) - this.teams.findIndex(t => t.id === b.id))
      )
    },
    loser() {
      return this.mapTeam(this.round.teams.find(team => !team.won_by))
    },
    winner() {
      return this.mapTeam(this.round.teams.find(team => team.id !== this.loser.id))
    },
  },

  methods: {
    mapTeam(team) {
      return {
        id: team.id,
        role: team.role,
        grid: ['Red', 'Blue'][this.orderedTeams.findIndex(t => t.id === team.id)],
        won_by: team.won_by,
      }
    },
  },
}
</script>
