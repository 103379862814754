<template>
  <div class="mht">
    <TeamCell
      link
      :id="id"
      :name="name"
      :image="image"
      variant="col"
      :to="{ query: { s: $route.query.s, map_ids: [mapId] } }"
    />
    <Breakdown :breakdown="breakdown" />
  </div>
</template>

<script>
import px from 'vue-types'

import Breakdown from '@/components/match/Breakdown.vue'

import TeamCell from '../../Table/cells/TeamCell.vue'

export default {
  name: 'MatchHeadTeam',
  components: { TeamCell, Breakdown },
  props: {
    breakdown: px.object,
    id: px.string,
    image: px.string,
    name: px.string,
    mapId: px.string.def(null),
  },
}
</script>

<style lang="scss" scoped>
.mht {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  &--score {
    &--num {
      &__atk {
        color: $atk;
      }

      &__def {
        color: $def;
      }
    }
  }
}
</style>
