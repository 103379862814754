<template>
  <div class="mh">
    <MatchHeadTeam v-bind="winnerTeam" :map-id="mapId" />
    <div class="mh--info">
      <div class="mh--icons">
        <VodStatusIndicator :status="vodStatus" />
        <BookmarkToggle :value="bookmark" @update="$emit('set:bookmark', $event)" />
      </div>
      <div class="mh--event">
        <EventCell v-bind="event" variant="row" />
      </div>
      <div class="mh--score">
        <span class="mh--score--num mh--score--num__winner">{{ winnerTeam ? winnerTeam.score : '-' }}</span>
        :
        <span class="mh--score--num mh--score--num__loser">{{ loserTeam ? loserTeam.score : '-' }}</span>
      </div>
      <div class="mh--info">
        <div class="mh--info--date">
          {{ date | localDate }}
        </div>
        <div class="mh--info--date">
          {{ date | localTime }}
        </div>
        <MapCell :id="mapId" variant="row" />
      </div>
    </div>
    <MatchHeadTeam v-bind="loserTeam" :map-id="mapId" />
  </div>
</template>

<script>
import px from 'vue-types'

import EventCell from '@/components/Table/cells/EventCell.vue'
import calcBreakdown from '@/utils/calcBreakdown.js'

import localDate from '../../filters/localDate.js'
import localTime from '../../filters/localTime.js'
import BookmarkToggle from '../generic/BookmarkToggle.vue'
import MapCell from '../Table/cells/MapCell.vue'
import VodStatusIndicator from '../UI/VodStatusIndicator.vue'

import MatchHeadTeam from './head/Team.vue'

export default {
  name: 'MatchHead',
  components: { EventCell, MapCell, BookmarkToggle, VodStatusIndicator, MatchHeadTeam },
  props: {
    bookmark: px.bool.def(false),
    date: px.string,
    event: px.object,
    mapId: px.string,
    teams: px.arrayOf(
      px.shape({
        logo_url: px.string,
        id: px.string,
        win: px.bool,
        name: px.string,
        roles: px.shape({
          first: px.oneOf(['atk', 'def']),
          second: px.oneOf(['atk', 'def']),
        }).loose,
        score: px.number,
      }).loose
    ),
    vodStatus: px.string,
  },
  data: () => ({
    bookmarked: false,
  }),
  computed: {
    normalizedTeams() {
      return this.teams?.map(team =>
        Object.freeze({
          breakdown: calcBreakdown(team),
          image: team.logo_url,
          id: team.id,
          winner: team.win,
          name: team.name,
          score: team.rounds_won,
        })
      )
    },

    winnerTeam() {
      return this.normalizedTeams?.find(team => team.winner) || this.normalizedTeams?.[0]
    },
    loserTeam() {
      return this.normalizedTeams?.find(team => team.id !== this.winnerTeam.id)
    },
  },
  filters: {
    localDate,
    localTime,
  },
}
</script>

<style lang="scss" scoped>
.mh {
  display: grid;
  align-items: center;
  justify-content: space-evenly;
  grid-template-columns: 1fr 1fr 1fr;

  &--info {
    align-self: stretch;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
  }

  &--icons {
    align-self: center;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 1em;
    padding-bottom: 0.5em;
  }

  &--score {
    font-size: 1.5rem;
    margin: 1em;

    &--num {
      &__winner {
        color: $highlight;
      }
    }
  }

  &--info {
    &--date {
      color: $text-muted;
    }
  }
}
</style>
